import { StrutEvent } from '../core';

/**
 * Model of calendar event item.
 */
export interface EventItem {
	date: Date;
	type: StrutEventEnum;
	items: StrutEventExtended[];
}

/**
 * Model of dashboard event item.
 */
export interface DailyEventItem {
	patient: string;
	items: StrutEventExtended[];
}

export enum StrutEventEnum {
	NONE = 'None',
	ADJUST = 'StrutAdjust',
	CHANGE = 'StrutChange'
}

export interface StrutEventExtended extends StrutEvent {
	header?: string;
	type?: StrutEventEnum;
	date?: Date;
	planName?: string;
	patientName?: string;
}

export function strutEventType(ev: StrutEventExtended): StrutEventEnum {
	if (ev.hasAcuteToChange) return StrutEventEnum.ADJUST;
	if (ev.hasSizeToChange) return StrutEventEnum.CHANGE;
	return StrutEventEnum.NONE;
}